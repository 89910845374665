import {setCookie} from 'cookies-next';
import router from 'next/router';
import {ICabinetUserRoles} from '@api/types/CabinetDashboard';
import {cookieExpiredUTC} from './formatters';
import { AGENT_GROUP_CODE, SUPPLIER_GROUP_CODE } from '@constants/accountGroups';

type Group =
  | {
      id: string;
      code: string;
      title: string;
    }
  | ICabinetUserRoles;

export const LKK_AB_test = (res: {
  groups: Group[];
  token: string;
  expired: string | null;
}) => {
  const isUseNewLKK = getIsUseNewLKK(res.groups);

  if (isUseNewLKK) {
    router.push(getCabinetLink(res.groups));
    setCookie('isUseNewLKK', 'true');
    setCookie('__at', res.token, {maxAge: cookieExpiredUTC(res.expired)});
  } else {
    setCookie('isUseNewLKK', 'false');
    setCookie('__at', res.token, {maxAge: cookieExpiredUTC(res.expired)});
    router.push(`/cabinet`);
  }
};

export const getIsUseNewLKK = (groups: Group[] | undefined) =>
  !!(groups ?? []).find(group => group.code === 'userNewLKK');



export const getPriorityGroup = (groups: Group[]) => {
  const priorities: Record<string, number> = {
    [SUPPLIER_GROUP_CODE]: 0,
    [AGENT_GROUP_CODE]: 1,
  };

  const sortedGroups = [...groups]
    .map((group: Group) => ({...group, priority: priorities[group.code] ?? 2}))
    .sort((a, b) => {
      const x = a.priority;
      const y = b.priority;
      if (x === y) return 0;
      return x < y ? -1 : 1;
    });

    return sortedGroups[0]

}
const getCabinetLink = (groups: Group[]) => {
  
  const links: Record<number, string> = {
    0: `/cabinet/v2/supplier/dashboard/`,
    1: `/cabinet/v2/agent/dashboard/`,
    2: `/cabinet/v2/client/dashboard/`,
  };
  
  return links[getPriorityGroup(groups).priority];
};
