import {ChangeEventHandler, FC} from 'react';
import {RadioButtonWrapper, AdditionalDiv} from './RadioButton.styles';

interface RadioButtonProps {
  name?: string;
  id: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  label: React.ReactNode;
  value: string;
  testID?: string;
  disabled?: boolean;
  invertedColors?: boolean;
}

const RadioButton: FC<RadioButtonProps> = ({
  name,
  id,
  onChange,
  checked,
  label,
  value,
  testID,
  invertedColors,
  disabled,
}) => {
  return (
    <RadioButtonWrapper htmlFor={id} invertedColors={invertedColors}>
      {invertedColors ? (
        <>
          <AdditionalDiv checked={checked ? true : false} htmlFor={id} />
          <input
            type="radio"
            id={id}
            name={name}
            onChange={onChange}
            value={value}
            checked={checked}
            data-testid={testID}
            disabled={disabled}
          />
        </>
      ) : (
        <input
          type="radio"
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          checked={checked}
          data-testid={testID}
          disabled={disabled}
        />
      )}
      {label && <label htmlFor={id}>{label}</label>}
    </RadioButtonWrapper>
  );
};

export default RadioButton;
