import {fontStyles, theme} from '@styles/theme';
import styled from 'styled-components';

export const RadioButtonWrapper = styled.label<{invertedColors?: boolean}>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer !important;
  
  label {
    ${fontStyles.mainText1}
    margin-left: 5px;
    cursor: pointer !important;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: ${({invertedColors}) =>
      invertedColors
        ? `1.5px solid ${theme.colors.brandGreenV2}`
        : `1px solid ${theme.colors.gray}`};
    transition: 0.2s all linear;
    outline: none;
    top: -1px;
    cursor: pointer !important;
  }

  input:checked {
    border: ${({invertedColors}) =>
      invertedColors
        ? `1.5px solid ${theme.colors.brandGreenV2}`
        : `5.5px solid ${theme.colors.brandGreenV2}`};
    background-color: ${({invertedColors}) =>
      invertedColors ? `${theme.colors.white}` : `${theme.colors.white}`};
  }
`;

export const AdditionalDiv = styled.label<{checked: boolean}>`
  position: absolute;
  top: 25%;
  right: 25%;
  background-color: ${theme.colors.brandGreenV2};
  max-width: 8px;
  min-width: 8px;
  max-height: 8px;
  min-height: 8px;
  z-index: ${({checked}) => (checked ? '5' : '-1')};
  border-radius: 50%;
  opacity: ${({checked}) => (checked ? '1' : '0')};
  transition: all 0.25s;
`;
