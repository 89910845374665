import RadioButton from '@components/atoms/RadioButton';
import {IDLE, PENDING} from '@constants/loadingState';
import {useAppDispatch} from '@hooks';
import {RootState} from '@store';
import {theme} from '@styles/theme';
import {getAgentCabRoute} from '@utils/getAgentCabRoute';
import {getSupplierCabRoute} from '@utils/getSupplierCabRoute';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useSelector} from 'react-redux';
import {
  UserMenuWrapper,
  UserMenuContainer,
  UserMenuInfoContainer,
  UserMenuInfo,
  CloseBtn,
  UserMenuButtons,
  UserMenuButtonsItem,
  UserMenuLinks,
  UserMenuLinksItem,
  RadioButtonTextWrapper,
  RoleDescription,
  RoleTitle,
  UserMenuInn,
  UserMenuLKTitle,
} from './CabHeaderUserMenu.styles';
import {IS_FACTORING_CABINET_ENABLED} from '@config/cabFactoring';
import {logoutUser} from '@store/slices/user';

interface CabHeaderUserMenuProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const CabHeaderUserMenu: FC<CabHeaderUserMenuProps> = ({isOpen, setIsOpen}) => {
  const router = useRouter();

  const isCabinet = router.pathname.startsWith('/cabinet');

  const dispatch = useAppDispatch();

  const menuItemList = [
    {
      path: '/cabinet/v2/info/',
      title: 'Профиль',
      icon: '/assets/icons/headerIcons/profileIcon.svg',
      alt: 'Иконка кнопки для перехода в профиль',
    },
    {
      path: '/cabinet/v2/settings/',
      title: 'Настройки',
      icon: '/assets/icons/headerIcons/settingsIcon.svg',
      alt: 'Иконка кнопки для перехода в настройки',
    },
    {
      path: '/',
      title: 'Вернуться на основной сайт',
      color: '#00755A',
      icon: '/assets/mobileMenu/mobileRalLogo.svg',
      isHidden: !isCabinet,
      alt: 'Иконка кнопки для перехода на основной сайт',
    },
    {
      path: '/',
      onClick: () => {
        dispatch(logoutUser());
      },
      title: 'Выход',
      color: theme.colors.opalRed,
      icon: '/assets/icons/headerIcons/logoutIcon.svg',
      alt: 'Иконка кнопки для выхода',
    },
  ];

  const {account, accountLoading} = useSelector((state: RootState) => state.user);

  const userFullName = account?.last_name ? `${account?.last_name} ${account?.name}` : '';
  const userFullNameWithPatronim = account?.second_name ? `${userFullName} ${account.second_name}` : '' ;

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noscroll');
      document.documentElement.style.overflowY = 'scroll';
    } else {
      document.body.classList.remove('noscroll');
      document.documentElement.style.overflowY = '';
    }
  }, [isOpen]);

  return (
    <UserMenuWrapper isOpen={isOpen}>
      <UserMenuContainer>
        {(account?.company_name || userFullName) && (
          <UserMenuInfoContainer>
            {accountLoading === PENDING ? (
              <Skeleton count={1} wrapper={UserMenuInfo} width={100} />
            ) : (
              <>
                <UserMenuInfo>{account?.company_name ||userFullNameWithPatronim ||  userFullName}</UserMenuInfo>
                {account?.inn && <UserMenuInn>ИНН {account?.inn}</UserMenuInn>}
              </>
            )}
          </UserMenuInfoContainer>
        )}
        <CloseBtn onClick={() => setIsOpen(prev => !prev)}>
          <Image
            src="/assets/mobileMenu/mobileMenuCloseBtn.svg"
            width={18}
            height={18}
            alt="Изображение иконки закрытия"
          />
        </CloseBtn>
        <UserMenuButtons>
          <UserMenuLKTitle>Личный кабинет</UserMenuLKTitle>
          {accountLoading === IDLE &&
            account &&
            account.roles?.find(role => role.code === 'client') && (
              <UserMenuButtonsItem
                href={'/cabinet/v2/client/dashboard/'}
                passHref
                isActive={router.asPath.startsWith('/cabinet/v2/client')}>
                <RadioButton
                  id=""
                  checked={router.asPath.startsWith('/cabinet/v2/client')}
                  onChange={() => null}
                  label="Клиент"
                  value=""
                />
              </UserMenuButtonsItem>
            )}
          {accountLoading === IDLE &&
            account &&
            account.roles?.find(role => role.code === 'agentRAL') && (
              <UserMenuButtonsItem
                href={getAgentCabRoute()}
                passHref
                isActive={router.asPath.startsWith('/cabinet/v2/agent')}>
                <RadioButton
                  id=""
                  checked={router.asPath.startsWith('/cabinet/v2/agent')}
                  onChange={() => null}
                  label="Дилер"
                  value=""
                />
              </UserMenuButtonsItem>
            )}
          {accountLoading === IDLE &&
            account &&
            account.roles?.find(role => role.code === 'supplier') && (
              <UserMenuButtonsItem
                href={getSupplierCabRoute()}
                passHref
                isActive={router.asPath.startsWith('/cabinet/v2/supplier')}>
                <RadioButton
                  id=""
                  checked={router.asPath.startsWith('/cabinet/v2/supplier')}
                  onChange={() => null}
                  label="Поставщик"
                  value=""
                />
              </UserMenuButtonsItem>
            )}
          {IS_FACTORING_CABINET_ENABLED &&
            accountLoading === IDLE &&
            account &&
            account.roles?.find(role => role.code === 'factoring_supplier') && (
              <UserMenuButtonsItem
                href={'/cabinet/v2/factoring-supplier/registers/'}
                passHref
                isActive={router.asPath.startsWith('/cabinet/v2/factoring-supplier')}>
                <RadioButton
                  id=""
                  checked={router.asPath.startsWith('/cabinet/v2/factoring-supplier')}
                  onChange={() => null}
                  label={
                    <RadioButtonTextWrapper>
                      <RoleTitle>Факторинг</RoleTitle>
                      <RoleDescription>Поставщик</RoleDescription>
                    </RadioButtonTextWrapper>
                  }
                  value=""
                />
              </UserMenuButtonsItem>
            )}
          {IS_FACTORING_CABINET_ENABLED &&
            accountLoading === IDLE &&
            account &&
            account.roles?.find(role => role.code === 'factoring_customer') && (
              <UserMenuButtonsItem
                href={'/cabinet/v2/factoring-customer/registers/'}
                passHref
                isActive={router.asPath.startsWith('/cabinet/v2/factoring-customer')}>
                <RadioButton
                  id=""
                  checked={router.asPath.startsWith('/cabinet/v2/factoring-customer')}
                  onChange={() => null}
                  label={
                    <RadioButtonTextWrapper>
                      <RoleTitle>Факторинг</RoleTitle>
                      <RoleDescription>Покупатель</RoleDescription>
                    </RadioButtonTextWrapper>
                  }
                  value=""
                />
              </UserMenuButtonsItem>
            )}
        </UserMenuButtons>
        <UserMenuLinks>
          {menuItemList.map((menuItem, i) => (
            <UserMenuLinksItem
              href={menuItem.path}
              passHref
              onClick={menuItem.onClick}
              key={i}
              color={menuItem.color}
              isHidden={menuItem?.isHidden}>
              <Image src={menuItem.icon} width={16} height={16} alt={menuItem.alt} />
              {menuItem.title}
            </UserMenuLinksItem>
          ))}
        </UserMenuLinks>
      </UserMenuContainer>
      {/*{typeof window === undefined && width! <= 680 && (*/}
      {/*  <UserMenuBackdrop isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />*/}
      {/*)}*/}
    </UserMenuWrapper>
  );
};

export default CabHeaderUserMenu;
