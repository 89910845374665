import {fontStyles, theme} from '@styles/theme';
import Link from 'next/link';
import styled from 'styled-components';

export const UserMenuWrapper = styled.div<{isOpen: boolean}>`
  z-index: 9999;

  @media (min-width: 681px) {
    display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
    position: absolute;
    top: 20px;
    padding-top: 30px;
    min-width: 290px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 680px) {
    right: 0;
    left: 0;
    position: fixed;
    transform: ${({isOpen}: {isOpen: boolean}) =>
      isOpen ? 'translateX(0%)' : 'translateX(150%)'};
    opacity: ${({isOpen}: {isOpen: boolean}) => (isOpen ? '1' : '0')};
    transition: transform 0.7s ease, opacity 0.3s;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: white;
    top: 0;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const UserMenuInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 10px 12px;
  position: relative;
  

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -10px;
    right: -10px;
    height: 1px;
    background-color: #dbdbdb;
  }
`;

export const UserMenuInfo = styled.div`
  font-family: ${theme.fonts.headline};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  color: ${theme.colors.brandGreenV2};
  padding-right: 40px;
`;

export const UserMenuLKTitle = styled.div`
  font-family: ${theme.fonts.headline};
  font-size: 13px;
  line-height: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  color: ${theme.colors.darkText};
  padding: 10px 11px;
`;

export const UserMenuInn = styled.p`
  ${fontStyles.mainText2}
  color: ${theme.colors.gray};
`

export const UserMenuContainer = styled.div`
  border-radius: 10px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.05);
  padding: 16px 10px 0;
  display: inline-flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 680px) {
    z-index: 10000;
    height: 100vh;
    top: 0;
    right: 0;
    position: fixed;
    padding: 20px 10px 6px;
    width: 100%;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const UserMenuButtons = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;


`;
export const UserMenuButtonsItem = styled(Link).withConfig<{isActive?: boolean}>({
  shouldForwardProp: prop => prop !== 'isActive',
})`
  padding: 12px 10px;
  background-color: ${({isActive}) => (isActive ? theme.colors.greenBG : 'transparent')};
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  *  {
  cursor: pointer;
  }

  
  label {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
    ${fontStyles.mainText2};

  }
`;

export const UserMenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  @media (min-width: 681px) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -10px;
      right: -10px;
      height: 1px;
      background-color: #dbdbdb;
    }
  }

  @media (max-width: 680px) {
    margin-top: auto;
    gap: 0;
  }
`;

export const UserMenuLinksItem = styled(Link).withConfig<{
  color?: string;
  isHidden?: boolean;
}>({
  shouldForwardProp: prop => prop !== 'isHidden',
})`
  ${fontStyles.mainText2};

  display: ${({isHidden}) => (isHidden ? 'none' : 'flex')};
  gap: 10px;
  padding: 15px 10px;
  color: ${({color}) => color ?? '#2B2A2A'} !important;
  width: 100%;
  box-sizing: border-box;

  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;
  }

  &:hover {
    color: ${({theme, color}) => (color ? color : theme.colors.brandGreenV2)} !important;
    transition: color 0.5s;
  }
`;

export const UserMenuBackdrop = styled.div<{isOpen: boolean}>`
  position: absolute;
  top: -37px;
  right: -16px;
  z-index: ${({isOpen}) => (isOpen ? '999' : '-1')};
  background-color: ${theme.colors.darkText};
  opacity: ${({isOpen}) => (isOpen ? '0.3' : '0')};
  transition: ${({isOpen}) => (isOpen ? '1s opacity 0.25s' : 'none')};
  width: 100vw;
  height: 100vh;
`;

export const CloseBtn = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 10px;
  justify-content: center;
  display: none;

  @media (max-width: 680px) {
    display: flex;
  }
`;

export const RadioButtonTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

export const RoleTitle = styled.div``;

export const RoleDescription = styled.div`
  font-size: 11px;
  border-radius: 24px;
  background-color: ${theme.colors.orange};
  padding: 0 6px;
`;
